/* eslint-disable no-undef */
export const delay = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

import X2JS from "x2js";
export const dataObjectToXml = dataObject => {
  const options = { compact: true, ignoreComment: true, spaces: 4 };
  var xml2Json = new X2JS();
  return xml2Json.js2xml(dataObject, options);
};

export const onFileChange = async (fileObj, e) => {
  let files = e.target.files || e.dataTransfer.files;
  let file = files[0];
  if (!files.length) {
    return;
  }
  fileObj.FileName = file.name;

  if (file.type.match(/image.*/)) {
    file = await resizeImage({ file: file, maxSize: 1920 });
  }
  fileObj.File = file;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const resizeImage = function (settings) {
  var file = settings.file;
  var maxSize = settings.maxSize;
  var reader = new FileReader();
  var image = new Image();
  var canvas = document.createElement("canvas");
  var dataURItoBlob = function (dataURI) {
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const max = bytes.length;
    let ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ia], { type: mime });
  };
  var resize = function () {
    let width = image.width;
    let height = image.height;
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL("image/jpeg");
    return dataURItoBlob(dataUrl);
  };
  return new Promise(function (ok, no) {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }
    reader.onload = function (readerEvent) {
      image.onload = function () {
        return ok(resize());
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const getPropertyValueOrDefault = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export const toAnchorTag = (text) => {
  const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  let text1=text.replace(exp, "<a  target='#' href='$1'>$1</a>");
  const exp2 =/(^|[^/])(www\.[\S]+(\b|$))/gim;
  return text1.replace(exp2, '$1<a target="#" href="https://$2">$2</a>');
};
