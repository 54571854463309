const buildProjectSelectObject = (queryData, uData) => {
  const dataObject = {
    qdbapi: {
      udata: uData,
      apptoken: "cqrux5rb59pciidfzmgqsbes7ti7",
      QueryString: queryData,
      fmt: "structured",
    },
  };
  if (queryData) {
    dataObject.qdbapi.query = `{'3'.GTE.'1'}`;
  }
  return dataObject;
};
  
export default buildProjectSelectObject;
