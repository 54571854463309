import DataObjectField from "@/utilities/classes/data-object-field";

const buildProjectSubmissionDataObject = (vData, base64File, FileName) => {
  // get today's date
  var today = new Date();
  const fields = [
    new DataObjectField(vData.relatedProject, 6),
    new DataObjectField(vData.typeOfFile, 9),
    new DataObjectField(vData.emailAddress, 11),
    new DataObjectField(vData.authorizedSignature, 12),
    new DataObjectField(vData.Department, 16),
    new DataObjectField(vData.sendNotification, 17),
    new DataObjectField(today, 14),
    new DataObjectField(vData.Title, 13),
    {
      _fid: "8",
      _filename: FileName,
      __text: base64File,
    },
  ];

  const dataObject = {
    qdbapi: {
      udata: "MBE_WBE_PROJECT",
      apptoken: "cqrux5rb59pciidfzmgqsbes7ti7",
      field: fields,
    },
  };
  return dataObject;
};

export default buildProjectSubmissionDataObject;
