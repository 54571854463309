<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <!-- <div class="content">
          <h2>Project Documents Uploads</h2>
          <ValidationObserver
            v-slot="{ errors }"
            tag="div"
          >
            <input-form
              ref="form"
              :errors="errors"
              name="projectDocumentsFrm"
              @submit.prevent="handleSubmit"
            >
              <br>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="projectDropdown"
                    mode="eager"
                    style="width: 100%"
                    :options="projectNames"
                    name="input-project"
                    placeholder="Select Project"
                    rules="required"
                    @change="getProjectInfo()"
                  />
                </div>
                <div class="column">
                  <vee-dropdown
                    v-model="Department"
                    mode="eager"
                    style="width: 100%"
                    :options="departmentNames"
                    name="input-department"
                    placeholder="Select Department"
                    rules="required"
                  />
                </div>
              </div> -->
              <br>
              <!--<h5>Type Of File*</h5>
              <div class="column">
                <div class="columns">
                  <vee-dropdown
                    v-model="typeOfFile"
                    style="width: 100%"
                    :options="['Contractors Business Utilization Report', 'Employment Report']"
                    name="input-file-type"
                    placeholder="Select Type of File"
                    rules="required"
                  />
                </div>
              </div>-->

              <div class="column">
                <p style="padding-left: 35%" >
                  <b>Attention Users: Site Closure Notification</b>
                </p>

                <callout type="danger" >
                  Effective <b>March 29, 2024</b>, this site will no longer be available. You may enter the details of the Business Utilization payments and Minority/Female employment data directly at <strong><a href="https://phdcphila.gob2g.com/" target="_blank" style="font-size: 20px;"> B2Gnow</a></strong>. Subcontractor Agreements and Release of Liens should be emailed to the Economic Opportunity Compliance Officer. Please contact <b>roneica.robinson@phdc.phila.gov</b> or <b>nancy.k.burns@phdc.phila.gov</b> for any questions.
                  
                  <strong><a href="https://phdcphila.gob2g.com/" target="_blank" style="font-size: 20px;"> B2Gnow</a></strong>
                </callout>
        
              </div>

              <!-- <callout type="warning">
                Please upload a Contractor Business Utilization Report or Minority/Female
                Monthly Employment report via the designated upload buttons below. 
                At least one document in either of the two catagories is required for submission.
              </callout>
              <br>
              <div class="column">
                <div class="columns">
                  <div style="margin-left: 1%;">
                    <h5>
                      Upload Contractors Business Utilization Report:
                    </h5>
                    <ul id="upload-list-project-documents">
                      <li
                        v-for="file in contractorDocuments"
                        :key="file.id"
                      >
                        <span>{{ file.name }} - </span>
                        <span
                          v-if="file.error"
                          style="color: red;"
                        >
                          Something went wrong - {{ file.errorMessage }}
                        </span>

                        <span
                          v-else-if="file.success"
                          class="is-phanatic-green"
                        >
                          Upload Successful. Please select another document to
                          upload if you have multiple documents.
                        </span>
                        <span
                          v-else-if="file.uploading"
                          class="is-flyers-orange"
                        >
                          Uploading Document...</span>
                      </li>
                    </ul>
                    <file-upload
                      ref="upload"
                      v-model="contractorDocuments"
                      :input-id="`file-upload-contractor-document`"
                      extensions="xls, xlsx, csv, docx "
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png,image/gif,image/jpeg,image/webp,application/pdf"
                      :multiple="true"
                      :size="1024 * 1024 * 4.5"
                      @input-filter="inputFilter"
                    >
                      <button class="button is-primary">
                        <i class="fa fa-plus" />
                        Select Reports to Upload
                      </button>
                    </file-upload>
                  </div>
                </div>
                <br>
                <div class="column">
                  <div class="columns">
                    <div>
                      <h5>
                        Upload Minority/Female Monthly Employment Report:
                      </h5>
                      <ul id="upload-list-employee-documents">
                        <li
                          v-for="file in employeeDocuments"
                          :key="file.id"
                        >
                          <span>{{ file.name }} - </span>
                          <span
                            v-if="file.error"
                            style="color: red;"
                          >
                            Something went wrong - {{ file.errorMessage }}
                          </span>

                          <span
                            v-else-if="file.success"
                            class="is-phanatic-green"
                          >
                            Upload Successful. Please select another document to
                            upload if you have multiple documents.
                          </span>
                          <span
                            v-else-if="file.uploading"
                            class="is-flyers-orange"
                          >
                            Uploading Document...</span>
                        </li>
                      </ul>
                      <file-upload
                        ref="upload"
                        v-model="employeeDocuments"
                        :input-id="`file-upload-employee-document`"
                        extensions="xls, xlsx, csv, docx "
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png,image/gif,image/jpeg,image/webp,application/pdf"
                        :multiple="true"
                        :size="1024 * 1024 * 4.5"
                        @input-filter="inputFilter"
                      >
                        <button class="button is-primary">
                          <i class="fa fa-plus" />
                          Select Reports to Upload
                        </button>
                      </file-upload>
                    </div>
                  </div><br>
                  <div class="column">
                    <div class="columns">
                      <div 
                        style="margin-left: -1%;"
                      >
                        <h5>
                          Subcontractor Agreements:
                        </h5>
                        <ul id="upload-list-subcontractor-agreements">
                          <li
                            v-for="file in subcontractorAgreementDocuments"
                            :key="file.id"
                          >
                            <span>{{ file.name }} - </span>
                            <span
                              v-if="file.error"
                              style="color: red;"
                            >
                              Something went wrong - {{ file.errorMessage }}
                            </span>

                            <span
                              v-else-if="file.success"
                              class="is-phanatic-green"
                            >
                              Upload Successful. Please select another document to
                              upload if you have multiple documents.
                            </span>
                            <span
                              v-else-if="file.uploading"
                              class="is-flyers-orange"
                            >
                              Uploading Document...</span>
                          </li>
                        </ul>
                        <file-upload
                          ref="upload"
                          v-model="subcontractorAgreementDocuments"
                          :input-id="`file-upload-subcontractor-agreement`"
                          extensions="xls, xlsx, csv, docx "
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png,image/gif,image/jpeg,image/webp,application/pdf"
                          :multiple="true"
                          :size="1024 * 1024 * 4.5"
                          @input-filter="inputFilter"
                        >
                          <button class="button is-primary">
                            <i class="fa fa-plus" />
                            Select Reports to Upload
                          </button>
                        </file-upload>
                      </div>
                    </div><br>
                    <div class="column">
                      <div class="columns">
                        <div 
                          style="margin-left: -2%;"
                        >
                          <h5>
                            Release of Liens:
                          </h5>
                          <ul id="upload-list-release-of-liens">
                            <li
                              v-for="file in releaseOfLiensDocuments"
                              :key="file.id"
                            >
                              <span>{{ file.name }} - </span>
                              <span
                                v-if="file.error"
                                style="color: red;"
                              >
                                Something went wrong - {{ file.errorMessage }}
                              </span>

                              <span
                                v-else-if="file.success"
                                class="is-phanatic-green"
                              >
                                Upload Successful. Please select another document to
                                upload if you have multiple documents.
                              </span>
                              <span
                                v-else-if="file.uploading"
                                class="is-flyers-orange"
                              >
                                Uploading Document...</span>
                            </li>
                          </ul>
                          <file-upload
                            ref="upload"
                            v-model="releaseOfLiensDocuments"
                            :input-id="`file-upload-release-of-liens`"
                            extensions="xls, xlsx, csv, docx "
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png,image/gif,image/jpeg,image/webp,application/pdf"
                            :multiple="true"
                            :size="1024 * 1024 * 4.5"
                            @input-filter="inputFilter"
                          >
                            <button class="button is-primary">
                              <i class="fa fa-plus" />
                              Select Reports to Upload
                            </button>
                          </file-upload>
                        </div>
                      </div><br>
                      <div class="columns">
                        <div
                          class="column"
                          style="margin-left: -2%;"
                        >
                          <vee-textbox
                            v-model="emailAddress"
                            mode="eager"
                            style="width: 100%"
                            name="input-email"
                            placeholder="Email Address"
                            rules="required|email"
                          />
                        </div>
                        <div
                          class="column"
                        >
                          <vee-textbox
                            v-model="Title"
                            name="input-title"
                            placeholder="Title"
                            rules="required"
                          />
                        </div>
                        <div
                          class="column"
                        >
                          <vee-textbox
                            v-model="authorizedSignature"
                            name="input-authorized-signature"
                            placeholder="Authorized Signature (required)"
                            rules="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="column is-2 is-offset-10">
                        <div v-if="contractorDocuments.length != 0 || employeeDocuments.length != 0 || subcontractorAgreementDocuments.length != 0 || releaseOfLiensDocuments != 0">
                          <button
                            type="button"
                            :disabled="
                              SubmitStatus.Value === 'PENDING' ||
                                SubmitStatus.Value === 'ERROR' ||
                                SubmitStatus.Value === 'OK'
                            "
                            class="button is-primary"
                            @click="handleSubmit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
                        Submitting...
                      </div>
                      <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                        OK
                      </div>
                      <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                        Error
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/utilities/options.js";
import axios from "axios";
import buildProjectSelectObject from "@/utilities/data-objects/data-fetch-object";
import buildProjectSubmissionObject from "@/utilities//data-objects/project-submission-object.js";
import buildProjectObject from "@/utilities/data-objects/project-data-fetch-object";
import FileUpload from "vue-upload-component";
import {
  dataObjectToXml,
  resizeImage,
  getBase64,
  getPropertyValueOrDefault,
} from "@/utilities/functions";

export default {
  name: 'HomePage',
  // components: { FileUpload },
  mixins: [options],
  data() {
    return {
      contractorDocuments:[],
      employeeDocuments:[],
      subcontractorAgreementDocuments:[],
      releaseOfLiensDocuments:[],
      projectNames: null,
      projectDropdown: null,
      relatedProject: null,
      typeOfFile: null,
      emailAddress: null,
      authorizedSignature: null,
      Department: null,
      sendNotification: true,
      Title: null,
    };
  },
  created() {
    // Gets the dates for the upcoming sessions and adds them to the date selector
    var vData = this;
    const dataObject = buildProjectSelectObject(
      "03-01-2023", //passes in variable to grab first entry after 3/1/23
      "MBE_FILE_UPLOAD_DATA_REQUEST",
    );
    const requestObj = {
      table: "btdr6gvmi",
      data: dataObject,
    };
    axios
      .post(
        "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/mbe-wbe-quickbase-request-proxy-reads",
        requestObj,
      )
      .then(response => {
        //Break down response by data, record, 0, f and set responseObject to it
        const responseObject = getPropertyValueOrDefault(
          ["data", "record", 0, "f"],
          response,
        );
        // Find value in responseObject by attribute id 49 and get the test
        vData.projectNames = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "49"),
        );
        //Get the dropdownDates text and split to array by semicolon(;) set to SessionsDates array
        this.projectNames = this.projectNames.split(" ; ");
      });
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async handleSubmit(){
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        var contractorFileEntries = [];

        // get the keys to the files to know the number of files to be uploaded
        var numberOfContractorFiles = Object.keys(this.contractorDocuments);
        vData.typeOfFile = "Contractors Business Utilization";
        // Remove all but the last file and place all other files in the FileEntries array
        for (var i = 0; i < numberOfContractorFiles.length - 1; i++) {
          var currentFile = this.contractorDocuments.shift();
          contractorFileEntries.push(currentFile);
        }

        if(this.contractorDocuments.length > 0){
        // Upload the last file
          await this.submitFile(this.contractorDocuments);

          if(vData.sendNotification == true){
            vData.sendNotification = false;
          }

          // Replace the file in Project files with the other files that need to be uploaded and upload them
          for (var k = 0; k < contractorFileEntries.length; k++) {
            this.contractorDocuments.splice(0, 1, contractorFileEntries[k]);
            await this.submitFile(this.contractorDocuments);
          }
          await this.sleep(4500);
        }


        var employeeFileEntries = [];

        // get the keys to the files to know the number of files to be uploaded
        var numberOfEmployeeFiles = Object.keys(this.employeeDocuments);
        vData.typeOfFile = "Minority/Female Monthly Employment";

        // Remove all but the last file and place all other files in the FileEntries array
        for (var i2 = 0; i2 < numberOfEmployeeFiles.length - 1; i2++) {
          var currentFile2 = this.employeeDocuments.shift();
          employeeFileEntries.push(currentFile2);
        }

        if(this.employeeDocuments.length > 0){
        // Upload the last file
          await this.submitFile(this.employeeDocuments);

          if(vData.sendNotification == true){
            vData.sendNotification = false;
          }

          // Replace the file in Project files with the other files that need to be uploaded and upload them
          for (var k2 = 0; k2 < employeeFileEntries.length; k2++) {
            this.employeeDocuments.splice(0, 1, employeeFileEntries[k2]);
            await this.submitFile(this.employeeDocuments);
          }
      
          await this.sleep(4500);
        }
        var subcontractorFileEntries = [];

        // get the keys to the files to know the number of files to be uploaded
        var numberOfsubcontractorFiles = Object.keys(this.subcontractorAgreementDocuments);
        vData.typeOfFile = "Subcontractors Agreements";
        // Remove all but the last file and place all other files in the FileEntries array
        for (var i3 = 0; i3 < numberOfsubcontractorFiles.length - 1; i3++) {
          var currentFile3 = this.subcontractorAgreementDocuments.shift();
          subcontractorFileEntries.push(currentFile3);
        }

        if(this.subcontractorAgreementDocuments.length > 0){
        // Upload the last file
          await this.submitFile(this.subcontractorAgreementDocuments);

          if(vData.sendNotification == true){
            vData.sendNotification = false;
          }

          // Replace the file in Project files with the other files that need to be uploaded and upload them
          for (var k3 = 0; k3 < subcontractorFileEntries.length; k3++) {
            this.subcontractorAgreementDocuments.splice(0, 1, subcontractorFileEntries[k3]);
            await this.submitFile(this.subcontractorAgreementDocuments);
          }
          await this.sleep(4500);
        }
        var rolFileEntries = [];

        // get the keys to the files to know the number of files to be uploaded
        var numberOfRolFiles = Object.keys(this.releaseOfLiensDocuments);
        vData.typeOfFile = "Release of Liens";

        // Remove all but the last file and place all other files in the FileEntries array
        for (var i4 = 0; i4 < numberOfRolFiles.length - 1; i4++) {
          var currentFile4 = this.releaseOfLiensDocuments.shift();
          rolFileEntries.push(currentFile4);
        }

        if(this.releaseOfLiensDocuments.length > 0){
        // Upload the last file
          await this.submitFile(this.releaseOfLiensDocuments);

          if(vData.sendNotification == true){
            vData.sendNotification = false;
          }

          // Replace the file in Project files with the other files that need to be uploaded and upload them
          for (var k4 = 0; k4 < rolFileEntries.length; k4++) {
            this.releaseOfLiensDocuments.splice(0, 1, rolFileEntries[k4]);
            await this.submitFile(this.releaseOfLiensDocuments);
          }   
          await this.sleep(4500);
        }

        this.finishApplication();
      }else{
        vData.SubmitStatus.Value = null;
      }
    },
    finishApplication() {
      var vData = this;
      if (!vData.contractorDocuments.some(i => i.uploading) && !vData.releaseOfLiensDocuments.some(i => i.uploading) && !vData.subcontractorAgreementDocuments.some(i => i.uploading) && !vData.employeeDocuments.some(i => i.uploading) && vData.SubmitStatus.Value != vData.SubmitStatus.Options.ERROR) {
        vData.$router.push({
          name: "Success",
        });
      }
    },
    async submitFile(uploadData) {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        if (uploadData.length) {
          let FileIdx = uploadData.length - 1;
          var fileObj = uploadData[FileIdx];
          fileObj.uploading = true;
          fileObj.error = false;
          fileObj.errorMessage = "";
          let file = fileObj.file;
          if (fileObj.type.match(/image.*/)) {
            file = await resizeImage({ file: file, maxSize: 1920 });
          }
          if (file.size >= 1024 * 1024 * 4.5) {
            fileObj.error = true;
            fileObj.errorMessage =
              "File is too large, please reduce your file's size, refresh, and try again.";
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
          } else {
            const base64File = await getBase64(file);
            const dataObject = buildProjectSubmissionObject(
              vData,
              base64File,
              fileObj.name,
            );
            const xmlDataObject = dataObjectToXml(dataObject);
            const requestObject = {
              table: "btdr6gvwg",
              action: "API_AddRecord",
              data: xmlDataObject,
            };

            axios
              .post(
                "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/mbe-wbe-quickbase-request-proxy",
                requestObject,
              )
              .then(() => {
                fileObj.uploading = false;
                fileObj.success = true;
                vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
              })
              .catch(() => {
                fileObj.error = true;
                fileObj.errorMessage =
                  "Please refresh the page to reupload your files";
                vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
              });
          }
        } else {
          fileObj.error = true;
          fileObj.errorMessage =
                  "Please refresh the page to reupload your files";
          vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
        }
      }
    },
    getProjectInfo() {
      // Returns the information about the selected session
      var vData = this;

      const dataObject = buildProjectObject(
        vData.projectDropdown,
        "MBE_FILE_UPLOAD_DATA_REQUEST",
      );
      const requestObj = {
        table: "btdr6gvmi",
        data: dataObject,
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/mbe-wbe-quickbase-request-proxy-reads",
          requestObj,
        )
        .then(response => {
          const responseObject = getPropertyValueOrDefault(
            ["data", "record", "f"],
            response,
          );
          vData.relatedProject = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "3"),
          );
        });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?|exe?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
