const options = {
  data() {
    return {
      YesNo: ["Yes", "No"],
      RequestTypes: ["Questions about eligibility", "Other questions"],
      ContactMethods: ["Phone", "Email", "In Person Appointment"],
      departmentNames: ["Development Finance", "Design & Construction", "Land Management", "Turn the Key"],
      UnitSizes: [
        "0 Bedrooms (studio)",
        "1 Bedroom",
        "2 Bedrooms",
        "3 Bedrooms",
        "4 Bedrooms",
        "5 Bedrooms",
        "6 Bedrooms",
      ],
      SubmitStatus: {
        Value: null,
        Options: {
          PENDING: "PENDING",
          OK: "OK",
          NOT_FOUND: "NOT_FOUND",
          ERROR: "ERROR",
        },
      },
    };
  },
};

export default options;
