var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("About")]),_vm._m(0),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" Welcome to PHDC’s Compliance Department! ")]),_vm._m(1),_c('div',{staticClass:"column"},[_vm._m(2),_c('callout',{attrs:{"type":"danger"}},[_vm._v(" Effective "),_c('b',[_vm._v("March 29, 2024")]),_vm._v(", this site will no longer be available. You may enter the details of the Business Utilization payments and Minority/Female employment data directly at "),_c('strong',[_c('a',{staticStyle:{"font-size":"20px"},attrs:{"href":"https://phdcphila.gob2g.com/","target":"_blank"}},[_vm._v(" B2Gnow")])]),_vm._v(". Subcontractor Agreements and Release of Liens should be emailed to the Economic Opportunity Compliance Officer. Please contact "),_c('b',[_vm._v("roneica.robinson@phdc.phila.gov")]),_vm._v(" or "),_c('b',[_vm._v("nancy.k.burns@phdc.phila.gov")]),_vm._v(" for any questions. "),_c('strong',[_c('a',{staticStyle:{"font-size":"20px"},attrs:{"href":"https://phdcphila.gob2g.com/","target":"_blank"}},[_vm._v(" B2Gnow")])])])],1),_c('b',[_vm._v("MBE/WBE Portal")]),_vm._m(3),_c('br'),_c('b',[_vm._v("Section 3 Reporting")]),_vm._m(4),_c('br'),_c('b',[_vm._v("Certified Payroll")]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/PHDC logo.png"),"alt":"PHDC logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('b',[_vm._v("Development Finance / Design & Construction / Land Management Projects")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"padding-left":"35%"}},[_c('b',[_vm._v("Attention Users: Site Closure Notification")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Use this portal to submit the Minority-Female Employment Report and the Contractors Business Utilization Report. Both reports are due on the 10th of the month for the previous month’s ending. For example, if the report is for month-ending July 31, 2023, then it must be submitted by August 10, 2023. For assistance, please contact "),_c('a',{attrs:{"href":"mailto:compliance@phdc.phila.gov"}},[_vm._v("compliance@phdc.phila.gov")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" If your project was awarded either CDGB or HOME funding, you must adhere to HUD regulations for Section 3. Please use the LCPtracker Portal to enter all data. For assistance please contact "),_c('a',{attrs:{"href":"mailto:compliance@phdc.phila.gov"}},[_vm._v("compliance@phdc.phila.gov")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Please use the LCPtracker Portal to submit all certified payroll. You may contact your appointed Contract Compliance Specialist at "),_c('a',{attrs:{"href":"mailto:gary.hawkins@phdc.phila.gov"}},[_vm._v("gary.hawkins@phdc.phila.gov")]),_vm._v(", "),_c('a',{attrs:{"href":"mailto:Jessica.hogan@phdc.phila.gov"}},[_vm._v("Jessica.hogan@phdc.phila.gov")]),_vm._v(", or "),_c('a',{attrs:{"href":"mailto:maria.reyes@phdc.phila.gov"}},[_vm._v("maria.reyes@phdc.phila.gov")])])
}]

export { render, staticRenderFns }