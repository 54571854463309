<template>
  <div id="app">
    <app-header
      :branding-image="deptLogo"
      app-title="Compliance"
    >
      <tabs-nav
        slot="tabs-nav"
        :links="navLinks"
      />
      <mobile-nav
        slot="mobile-nav"
        :links="navLinks"
      />
    </app-header>
    <main class="content">
      <router-view />
    </main>
    <app-footer
      :is-sticky="true"
      :links="navLinks"
    />
  </div>
</template>

<script>
import logo from '@/assets/DPD BELL logo_w.png';

export default {
  data() {
    return {
      deptLogo: {
        src: logo,
        alt: 'Philadelphia Department of Planning and Development logo',
        width: "190px",
      },
      navLinks: [
        // {
        //   href: "Home",
        //   text: "Home",
        // },
        {
          href: "About",
          text: "About",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";

#google_translate_element {
  max-height: 40px !important;
  background-color: transparent !important;
}
#google_translate_element > *,
#google_translate_element a,
.goog-te-menu-value > * {
  color: black !important;
  background-color: white !important;
}
.goog-te-banner-frame {
  top: 112px !important;
}
#google_translate_element a {
  padding: 0 !important;
}

main.content{
  margin-top: 145px !important;
}

@media screen and (max-width: 768px) {
  main.content{
  margin-top: 72px !important;
}

  .goog-te-banner-frame {
    top: 70px !important;
    z-index: 5;
  }
  #google_translate_element > *,
  #google_translate_element a,
  .goog-te-menu-value > * {
    color: black !important;
    background-color: transparent !important;
  }
  #google_translate_element {
    display: table !important;
  }
}


body {
  min-height: auto !important;
}

a{
  word-break: break-word;
}

#trusted-site{
  display: none;
}
</style>
