import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Success from "../views/Success.vue";
import About from "../views/About.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/Success",
    name: "Success",
    component: Success,
  },
  {
    path: "/",
    name: "About",
    component: About,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
});

export default router;
